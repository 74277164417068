import React from 'react'

export default function Logo({className = ''}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      fill="currentColor"
      viewBox="0 0 1300 150"
      className={className}
    >
      <path d="m150.05 4.5613-143.81 141.06" strokeWidth=".99034"/>
      <path d="m139.64 0.62681-139.64 143.44 5.0639 5.9316 140.98-142.19z" strokeWidth=".9841"/>
      <path d="m71.37 149.54c-12.566 0.1253-24.951-2.9412-35.951-8.9012-10.721-5.8571-19.656-14.412-25.886-24.783-6.3896-10.96-9.6627-23.402-9.4775-36.026-0.19834-12.563 3.0765-24.945 9.4765-35.829 6.2288-10.371 15.164-18.926 25.885-24.784 11-5.96 23.385-9.0264 35.951-8.9011 12.499-0.13595 24.817 2.9323 35.736 8.9011 10.74 5.7583 19.63 14.341 25.67 24.784 12.319 23.794 12.287 50.237 0 71.856-6.1136 10.387-14.985 18.952-25.67 24.784-10.919 5.9688-23.237 9.0371-35.736 8.9012zm0-30.201c11.054 0 19.874-3.6157 26.461-10.847 6.5863-7.2314 9.9418-16.786 10.066-28.663 0-12.009-3.3555-21.563-10.066-28.663-6.7116-7.0993-15.532-10.71-26.461-10.832-11.188 0-20.138 3.55-26.849 10.65s-9.9994 16.716-9.8655 28.847c0 12 3.2885 21.62 9.8655 28.86 6.7203 7.1 15.67 10.65 26.849 10.65z" strokeWidth=".99034"/>
      <path d="m529.21 0.30281-138.43 145.95 4.4053 3.9902 138.43-145.95z" strokeWidth=".98977"/>
      <path d="m899.99 91.058c-0.0133 3.5575-0.21023 7.112-0.58997 10.65h-86.47c0.66129 10.466 4.2804 18.666 10.857 24.601 6.688 5.8028 15.362 8.9161 24.289 8.7177 7.7462 0 14.194-1.7443 19.342-5.2328 5.1313-3.4797 8.9877-8.4838 11.001-14.275h19.342c-2.7613 10.007-8.8817 18.808-17.372 24.981-8.6862 6.3192-19.476 9.4838-32.37 9.4936-9.6601 0.15358-19.192-2.1861-27.639-6.7845-8.0201-4.5339-14.57-11.199-18.896-19.226-4.7525-8.959-7.1311-18.947-6.9166-29.044-0.21387-9.9927 2.0931-19.882 6.7156-28.79 4.1994-7.9861 10.701-14.584 18.695-18.972 8.5847-4.6194 18.253-6.9537 28.042-6.7708 9.509-0.18567 18.901 2.0811 27.236 6.5736 7.7198 4.2226 14.028 10.547 18.163 18.21 4.4368 7.9198 6.7013 16.834 6.5716 25.87zm-18.551-3.6674c0.17529-6.0515-1.4004-12.028-4.5448-17.237-2.9267-4.8227-7.2595-8.6747-12.439-11.059-5.3239-2.6221-11.213-3.9477-17.17-3.8645-8.5826-0.2011-16.918 2.8272-23.297 8.4637-6.3276 5.6425-9.9468 13.518-10.857 23.628z" strokeWidth=".99034"/>
      <path d="m217.27 38.979c12.904 0 23.167 4.1284 30.789 12.385 7.6216 8.2567 11.504 19.49 11.648 33.699v63.124h-33.55v-58.666c0-7.2314-1.9176-12.85-5.7527-16.857-3.8351-4.0058-8.9688-6.0041-15.401-5.9949-6.4616 0-11.595 1.9983-15.401 5.9949-3.8058 3.9966-5.7234 9.6155-5.7527 16.857v58.666h-33.65v-108.05h33.765v14.331c3.5173-4.886 8.2837-8.7779 13.82-11.285 6.0694-2.8805 12.744-4.3206 19.486-4.2039z" strokeWidth=".99034"/>
      <path d="m378.19 92.413c-3e-3 3.2388-0.20046 6.4746-0.58997 9.6907h-76.404c0.51797 6.7142 2.6895 11.886 6.5146 15.517 3.9362 3.4817 9.0814 5.354 14.38 5.2328 8.4175 0 14.275-3.4888 17.573-10.467h35.951c-1.8164 7.0654-5.2601 13.628-10.066 19.184-4.8531 5.7554-11.001 10.328-17.961 13.358-7.6468 3.309-15.932 4.9594-24.289 4.8385-10.017 0.16879-19.914-2.161-28.761-6.7708-8.3526-4.4471-15.211-11.175-19.745-19.368-4.897-9.0551-7.343-19.191-7.1036-29.439-0.25657-10.225 2.1017-20.351 6.8596-29.453 4.5364-8.191 11.394-14.918 19.745-19.368 18.04-8.9768 39.347-9.0499 57.451-0.19715 8.1776 4.2722 14.901 10.799 19.342 18.775 4.8826 8.7093 7.3331 18.529 7.1036 28.466zm-34.513-8.703c0.23045-5.1673-1.9502-10.156-5.9247-13.555-8.5389-6.6392-20.59-6.7205-29.221-0.19715-4.0004 3.5799-6.5272 8.4719-7.1036 13.753z" strokeWidth=".99034"/>
      <path d="m454.45 149.54c-12.211 0.15458-24.251-2.821-34.93-8.6324-10.45-5.8558-19.11-14.35-25.08-24.601-6.1352-10.9-9.2632-23.182-9.0745-35.631-0.20056-12.388 2.9293-24.609 9.0745-35.434 5.8926-10.308 14.571-18.82 25.08-24.601 21.792-11.866 48.284-11.866 70.076 0 10.426 5.7056 19.038 14.133 24.879 24.346 6.1655 10.888 9.2956 23.178 9.0745 35.631 0.22107 12.453-2.909 24.743-9.0745 35.631-5.853 10.285-14.454 18.81-24.879 24.657-10.743 5.8534-22.858 8.8534-35.146 8.703zm0-15.29c9.0767 0.14019 18.022-2.1364 25.885-6.5873 7.7769-4.4066 14.082-10.934 18.148-18.789 4.6106-8.7044 6.9198-18.405 6.7156-28.212 0-10.843-2.2385-20.247-6.7156-28.212-4.0375-7.8152-10.27-14.335-17.961-18.789-16.223-8.7825-35.906-8.7825-52.129 0-7.7172 4.4851-14.007 10.992-18.163 18.789-4.3431 8.003-6.5146 17.407-6.5146 28.212-0.20632 9.7844 2.0302 19.47 6.5146 28.212 4.156 7.7974 10.446 14.304 18.163 18.789 7.933 4.4325 16.929 6.7066 26.058 6.5873z" strokeWidth=".99034"/>
      <path d="m582.41 56.598h-22.693v91.589h-17.976v-91.589h-14.006v-14.529h14.006v-7.5466c0-11.877 3.0965-20.529 9.2895-25.955 6.1937-5.426 16.26-8.2002 30.199-8.3224v14.713c-7.8996 0-13.494 1.5517-16.782 4.6551-3.1445 2.9621-4.7214 7.9321-4.7307 14.91v7.5466h22.693z" strokeWidth=".99034"/>
      <path d="m638.51 56.598h-22.693v91.589h-17.976v-91.589h-14.006v-14.529h14.006v-7.5466c0-11.877 3.0965-20.529 9.2895-25.955 6.1937-5.426 16.26-8.2002 30.199-8.3224v14.713c-7.8996 0-13.494 1.5517-16.782 4.6551-3.1445 2.9621-4.7214 7.9321-4.7307 14.91v7.5466h22.693z" strokeWidth=".99034"/>
      <path d="m659.84 24.832c-6.7859 0.06395-12.304-5.3483-12.238-12.005-0.0647-6.6559 5.4528-12.068 12.238-12.004 3.1466-0.0563 6.167 1.212 8.2975 3.484 2.3185 2.2232 3.6029 5.2838 3.5518 8.4637 0.0545 3.1806-1.2304 6.2424-3.5518 8.4637-2.1063 2.3183-5.1319 3.6298-8.2975 3.5968zm8.6285 17.237v106.12h-17.875v-106.12z" strokeWidth=".99034"/>
      <path d="m683.59 94.938c0-10.974 2.2386-20.529 6.7156-28.663 4.2028-7.9879 10.637-14.637 18.551-19.17 8.3729-4.6019 17.842-6.9386 27.438-6.7708 13.259 0 24.202 3.1596 32.831 9.4789 8.6282 6.3192 14.333 15.098 17.113 26.336h-19.342c-1.6614-6.196-5.5084-11.614-10.857-15.29-5.263-3.7619-11.845-5.6428-19.745-5.6428-10.267 0-18.555 3.4843-24.864 10.453-6.3083 6.9686-9.4671 16.716-9.4765 29.242 0 12.78 3.1588 22.654 9.4765 29.622 6.3177 6.9686 14.606 10.457 24.864 10.467 7.8996 0 14.481-1.8103 19.745-5.4309 5.3702-3.7557 9.2155-9.2512 10.857-15.517h19.342c-2.5916 10.397-8.7193 19.617-17.372 26.138-8.6862 6.4605-19.544 9.6908-32.572 9.6908-9.5982 0.16976-19.069-2.1721-27.438-6.7845-7.9057-4.5176-14.339-11.146-18.551-19.114-4.4864-8.2659-6.725-17.947-6.7156-29.044z" strokeWidth=".99034"/>
      <path d="m964.5 33.404h54.102v-33.404h-95.687v150h95.687v-33.404h-54.102v-27.021h47.737v-31.702h-47.737z" strokeWidth="5.3116"/>
      <path d="m1114.9 150h46.464l-33.734-58.511c10.608-3.1915 18.883-8.7234 24.399-16.383 5.5163-7.6596 8.4867-16.809 8.4867-27.021 0-8.9362-2.1217-17.234-6.365-24.468s-10.608-12.979-18.883-17.234c-8.4866-4.2553-18.671-6.383-30.552-6.383h-65.559v150h41.585v-55.319h4.4555zm-34.159-83.83v-31.277h20.58c5.5163 0 9.5474 1.4894 12.518 4.2553 2.9704 2.766 4.4555 6.5958 4.4555 11.277 0 5.1064-1.4851 9.1489-4.2433 11.702-2.9703 2.766-7.2136 4.0426-12.73 4.0426z" strokeWidth="5.3116"/>
      <path d="m1291.2 74.894c4.2433-7.4468 6.3649-15.745 6.3649-24.894 0-9.7872-2.1216-18.298-6.3649-25.957-4.2434-7.4468-10.608-13.404-19.095-17.66-8.4866-4.2553-18.671-6.383-30.552-6.383h-62.165v150h41.585v-50.638h20.58c12.094 0 22.278-2.1277 30.764-6.5958 8.4866-4.4681 14.639-10.426 18.883-17.872zm-36.068-24.894c0 10.851-5.9406 16.17-17.61 16.17h-16.549v-32.553h16.549c11.669 0 17.61 5.5319 17.61 16.383z" strokeWidth="5.3116"/>
    </svg>
  )
}
