import { useLayoutEffect, useRef } from 'react'
import { processClasses } from '/src/vendors/runcss'

export { addCss, addClass, processClasses, configure } from '/src/vendors/runcss'


export const useClasses = () => {
  const ref = useRef()
  useLayoutEffect(() => {
    processRef(ref)
  })
  return ref
}

export const processRef = (ref) => {
  const parent = typeof ref == 'string' ? document.querySelector(ref) : ref.current
  if (parent) {
    if (parent.className) processClasses(parent.className)
    parent.querySelectorAll('*[class]').forEach(function(element) {
      if (element.className) processClasses(element.className)
    })
  }
}
